@import "../../assets/styles/utils";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  height: $header-height;
  width: 100%;
  min-width: 1280px;

  // active
  .comp-header {
    background-color: transparent;
  }
  .comp-header-nav {
    opacity: 0;
    transition: opacity .15s;
  }

  & {
    transition: all .25s;
    .comp-header-nav-item,
    .comp-header-pos {
      transition: none;
    }
  }
  &:not(:hover).normal {
    & {
      color: #fff;
    }
    .comp-header-pos {
      border-color: #fff;
    }
    .comp-header-pos{
      background-color: rgba(#000, 0.2);
    }
  }
  
  .comp-header-logo{
    opacity: 0;
  }
  &:hover, &.active {
    .comp-header-logo{
      opacity: 1;
    }
    .comp-header-nav {
      opacity: 1;
    }
  }
  &:hover, &.active {
    background-color: #fff;
  }
}

.banner {
  .swiper-slide {
    overflow: hidden;
  }
  &-swiper {
    height: calc(100vh - 50px);
  }
  &-bg {
    display: block;
    height: 100%;
    @include bg-cover;
  }
  &-img {
    display: block;
    width: 100%;
  }
  &-pagination {
    position: absolute;
    bottom: 30px;
    left: 30px;
    display: flex;
    margin-right: 18px;
    z-index: 1;
    i {
      position: relative;
      border-radius: 50%;
      border: 4px solid transparent;
      cursor: pointer;
      &:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
    i.active {
      border-color: rgba(#fff, 0.3);
    }
    i:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.search {
  position: relative;
  z-index: 2;
  // margin-left: 200px;
  @include res(margin-bottom, 65px, 55 / 65);
  // @include res(margin-top, -90px, 0.6);
  width: 100%;
  &-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  &-body {
    background-color: #F2F6F7;
    @include res(padding-top, 45px, 30 / 45);
    @include res(padding-left, 100px, 70 / 100);
    @include res(padding-bottom, 45px, 30 / 45);
    @include res(padding-right, 100px, 70 / 100);
    width: 76%;
  }
  &-more {
    margin-left: 28px;
    color: $color-green;
  }
  &-map {
    width: 24%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-image: url(./images/index-search-map.jpg);
    @include bg-cover;
    @include res(padding-left, 110px, 70 / 110);
    @include res(padding-right, 110px, 70 / 110);
    @include res(font-size, 22px, 18 / 22);
    i {
      font-size: 40px;
      margin-right: 8px;
    }
    // hover
    &:before {
      content: "";
      transition: all .15s;
      background-color: rgba(#000, 0.14);
      opacity: 0;
      @include cover;
    }
    &:hover:before {
      opacity: 1;
    }
  }
}

.project {
  @include res(margin-bottom, 80px, 60 / 80);
  &-title {
    text-align: center;
    @include res(font-size, 40px, 30 / 40);
    @include res(margin-bottom, 40px, 30 / 40);
  }
  &-tab {
    color: $color-fs-gray;
    @include res(margin-bottom, 127px, 80 / 127);
    &, &-item, &-item i {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-item {
      p {
        @include res(font-size, 22px, 18 / 22);
      }
      i {
        width: 16px;
        height: 16px;
        font-style: normal;
        margin: 0 12px;
      }
      i:nth-child(1) {
        display: none;
      }
      i:nth-child(2) {
        font-weight: bold;
      }
      &:hover {
        color: $color-green;
      }
      &.active {
        color: $color-green;
        i:nth-child(1) {
          display: flex;
        }
        i:nth-child(2) {
          display: none;
        }
      }
    }
  }
  &-area {
    position: relative;
    margin-left: $layout-container-gutter;
    @include res(padding-top, 38px, 30 / 38);
    @include res(padding-left, 80px, 50 / 80);
    @include res(padding-bottom, 50px, 40 / 50);
    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 140%;
      height: 110%;
      background-color: $color-green-light;
    }
    > * {
      position: relative;
    }
    &-wrapper {
      position: relative;
      height: 420px;
    }
    &-title {
      color: $color-green;
      position: relative;
      @include res(font-size, 32px, 26 / 32);
      @include res(padding-bottom, 20px, 16 / 20);
      @include res(margin-bottom, 50px, 25 / 50);
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color-green;
      }
    }
    &-link {
      color: $color-fs-gray;
      @include res(font-size, 18px, 16 / 18);
      @include res(margin-bottom, 40px, 30 / 40);
      a {
        display: block;
        padding: 13px 0;
      }
      a:hover {
        color: $color-green;
      }
    }
    &-desc {
      color: $color-fs-gray;
      line-height: 2;
    }
    &-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
    }
    &-control {
      position: relative;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      color: $color-green;
      margin-right: 15px;
      // hover
      &:after {
        content: "";
        border: 2px solid $color-green;
        border-radius: 50%;
        opacity: 0;
        transform: scale(1.3);
        filter: blur(3px);
        transition: all .4s;
        @include cover;
      }
      &:hover:after {
        opacity: 1;
        transform: scale(1);
        filter: blur(0);
      }
    }
  }
  &-item {
    position: relative;
    width: 400px;
    height: 470px;
    box-sizing: border-box;
    .comp-project {
      height: 100%;
    }
  }
  &-body {
    cursor: move;
    &-item {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  &-swiper {
    &-s {
      padding-top: 10px;
    }
  }
  &-more {
    text-align: center;
    height: 100%;
    background-image: url(./images/index-project-more.jpg);
    color: $color-green;
    @include bg-cover;
    @include res(font-size, 22px, 18 / 22);
    @include res(padding-top, 135px, 100 / 135);
  }
}

.link {
  &-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    overflow: hidden;
    @include res(font-size, 22px, 18 / 22);
    @include res(height, 172px, 150 / 172);
    &-pic {
      @include cover;
      @include bg-cover;
    }
    p {
      position: relative;
    }
    // hover
    &-pic {
      transition: all .3s
    }
    &:hover &-pic {
      transform: scale(1.1);
    }
  }
}

.activity {
  @include res(margin-bottom, 75px, 60 / 75);
  &-pic, &-body {
    @include res(height, 580px, 500 / 580);
  }
  &-pic {
    &-item {
      @include cover;
      @include bg-cover;
    }
  }
  &-control {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 5;
    height: 70px;
    padding-left: 34px;
    padding-right: 34px;
    color: #fff;
    background-color: $color-green;
    &-pagination {
      display: flex;
      margin-right: 18px;
      i {
        position: relative;
        border-radius: 50%;
        border: 4px solid transparent;
        &:before {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #fff;
        }
      }
      i.active {
        border-color: rgba(#fff, 0.3);
      }
      i:not(:last-child) {
        margin-right: 12px;
      }
    }
    &-btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      cursor: pointer;
      font-size: 20px;
      outline: none;
      // hover
      // hover
      &:after {
        content: "";
        border: 2px solid #fff;
        border-radius: 50%;
        opacity: 0;
        transform: scale(1.3);
        filter: blur(3px);
        transition: all .4s;
        @include cover;
      }
      &:hover:after {
        opacity: 1;
        transform: scale(1);
        filter: blur(0);
      }
    }
    &-index {
      margin: 0 15px;
    }
  }
  &-body {
    background-color: $color-green-light;
    @include res(padding-top, 110px, 90 / 110);
  }
  &-title {
    display: flex;
    align-items: center;
    color: #333333;
    @include res(font-size, 40px, 32 / 40);
    @include res(margin-bottom, 80px, 65 / 80);
    &:before {
      content: "";
      height: 1px;
      width: 60%;
      margin-right: 16px;
      background-color: $color-green;
    }
  }
  &-item {
    @include res(padding-left, 70px, 50 / 70);
    @include res(padding-right, 70px, 50 / 70);
    &-title {
      color: #595a5b;
      @include res(font-size, 28px, 24 / 28);
      @include res(margin-bottom, 36px, 24 / 36);
    }
    &-desc {
      line-height: 1.8;
      color: $color-fs-gray;
      @include res(margin-bottom, 36px, 24 / 36);
    }
    &-more {
      color: $color-green;
    }
  }
}

.news1 {
  // 箭头效果
  // 箭头延伸出去的宽度
  $arrow-width: 20px;

  @include res(margin-bottom, 75px, 60 / 75);
  &-title, &-item {
    @include res(height, 290px, 270 / 290);
  }
  &-title {
    display: flex;
    align-items: center;
    background-color: $color-green-light;
    @include res(font-size, 40px, 36 / 40);
    @include res(padding-left, 100px, 80 / 100);
    &:after {
      content: "";
      flex-grow: 1;
      height: 1px;
      background-color: $color-green;
      margin-left: 16px;
    }
  }
  &-item {
    display: flex;
    overflow: hidden;
    font-size: 14px;
    & {
      background-color: #fff;
      // dir
      &.reverse {
        background-color: $color-green-light;
      }
    }
    &-pic {
      position: relative;
      height: 100%;
      width: calc(50% + #{$arrow-width});
      flex-shrink: 0;
      overflow: hidden;
      b {
        display: block;
        height: 100%;
        width: 100%;
        @include cover;
        @include bg-cover;
        // dir
        .reverse & {
          left: auto;
        }
        // hover
        & {
          transition: all .15s;
        }
        a:hover & {
          transform: scale(1.1);
        }
      }
      // 箭头效果
      &:before, &:after {
        content: "";
        position: absolute;
        z-index: 1;
        height: 100%;
        width: $arrow-width;
        // color
        & {
          background-color: #fff;
        }
        .reverse & {
          background-color: $color-green-light;
        }
        // dir
        & {
          right: 0;
        }
        .reverse & {
          left: 0;
        }
      }
      &:before {
        bottom: 50%;
        // dir
        & {
          transform: skewY(45deg);
        }
        .reverse & {
          transform: skewY(-45deg);
        }
      }
      &:after {
        top: 50%;
        & {
          transform: skewY(-45deg);
        }
        .reverse & {
          transform: skewY(45deg);
        }
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      flex-grow: 1;
      @include res(padding-top, 36px, 30 / 36);
      @include res(padding-bottom, 36px, 30 / 36);
      @include res(padding-left, 58px, 50 / 58);
      @include res(padding-right, 58px, 50 / 58);
    }
    &-time {
      color: $color-fs-gray;
      @include res(margin-bottom, 20px, 18 / 20);
    }
    &-title {
      line-height: 1.3;
      @include res(font-size, 24px, 20 / 24);
    }
    &-category {
      color: $color-fs-gray;
    }
  }
}

.news2 {
  @include res(margin-bottom, 75px, 60 / 75);
  &-header {
    display: flex;
    justify-content: space-between;
    @include res(margin-bottom, 45px, 36 / 40);
  }
  &-title {
    @include res(font-size, 40px, 36 / 40);
  }
  &-tab {
    display: flex;
    @include res(font-size, 22px, 18 / 22);
    &-item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        &:after {
          content: "";
          height: 5px;
          width: 5px;
          border-radius: 50%;
          background-color: #C2C2C2;
          @include res(margin-left, 26px, 20 / 26);
          @include res(margin-right, 26px, 20 / 26);
        }
      }
      p {
        padding: 12px 0;
      }
      // hover
      p {
        transition: all .15s;
        border-bottom: 1px solid transparent;
      }
      &.active, &:hover {
        p {
          color: $color-green;
          border-bottom: 1px solid $color-green;
        }
      }
    }
  }
  &-body {
    &-item {
      box-sizing: border-box;
    }
  }
  &-list {
    background-color: $color-green-light;
    @include res(padding-top, 35px, 25 / 35);
    @include res(padding-bottom, 35px, 25 / 35);
    @include res(padding-left, 90px, 80 / 90);
    @include res(padding-right, 90px, 80 / 90);
  }
  &-item {
    display: block;
    @include res(padding-top, 25px, 20 / 25);
    @include res(padding-bottom, 25px, 20 / 25);
    &:not(:last-child) {
      border-bottom: 1px solid #C4D3D7;
    }
    &-time {
      color: $color-fs-gray;
      font-size: 14px;
      padding-left: 12px;
    }
    &-body {
    }
    &-title {
      margin-bottom: 10px;
      line-height: 1.2;
      font-size: 18px;
    }
    &-category {
      color: $color-fs-gray;
      font-size: 14px;
    }
    // hover
    &-title {
      transition: all .15s;
    }
    &:hover &-title {
      color: $color-green;
    }
  }
  &-more {
    color: $color-green;
    text-align: right;
    @include res(margin-top, 25px, 20 / 25);
  }
  &-pic {
    &-swiper {
      height: 100%;
    }
    &-item {
      background-color: #000;
      @include cover;
      b {
        opacity: 0.8;
        @include cover;
        @include bg-cover;
      }
    }
    &-control {
      .comp-control-btn-item {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        &.prev {
          left: 15px;
        }
        &.next {
          right: 15px;
        }
      }
      .comp-control-btn-item:after {
        transform: scale(1);
        filter: blur(0);
        opacity: 1;
      }
    }
    &-footer {
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      font-size: 16px;
      padding: 20px;
      background-color: rgba($color-green, 0.7);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      p {
        @extend %ellipsis;
        width: 70%;
      }
    }

    // hover
    &-control, &-footer {
      opacity: 0;
      transition: all .2s;
    }
    &:hover &-control,
    &:hover &-footer {
      opacity: 1;
    }
  }
}
$color-green: #009DA0;
$color-green-light: #F2F6F7;
$color-fs-gray: #868686;
$color-fs-black: #333333;

// 响应式断点，需要从小到大写
$layout-responsive-breakpoint: (
  xs: (min-width: 0, container-width: 100%),
  sm: (min-width: 1366px, container-width: 100%),
  md: (min-width: 1440px, container-width: 100%),
  lg: (min-width: 1680px, container-width: 100%)
);

// container 槽宽
$layout-container-gutter: 50px;

// header 高度
$header-height: 125px;

// 汉堡包按钮
$hamburger-layer-width: 16px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-color: $color-green;

$jason-map-theme: $color-green;
.news2-pic-footer p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  height: 125px;
  width: 100%;
  min-width: 1280px;
}

.header .comp-header {
  background-color: transparent;
}

.header .comp-header-nav {
  opacity: 0;
  transition: opacity .15s;
}

.header {
  transition: all .25s;
}

.header .comp-header-nav-item,
.header .comp-header-pos {
  transition: none;
}

.header:not(:hover).normal {
  color: #fff;
}

.header:not(:hover).normal .comp-header-pos {
  border-color: #fff;
}

.header:not(:hover).normal .comp-header-pos {
  background-color: rgba(0, 0, 0, 0.2);
}

.header .comp-header-logo {
  opacity: 0;
}

.header:hover .comp-header-logo, .header.active .comp-header-logo {
  opacity: 1;
}

.header:hover .comp-header-nav, .header.active .comp-header-nav {
  opacity: 1;
}

.header:hover, .header.active {
  background-color: #fff;
}

.banner .swiper-slide {
  overflow: hidden;
}

.banner-swiper {
  height: calc(100vh - 50px);
}

.banner-bg {
  display: block;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.banner-img {
  display: block;
  width: 100%;
}

.banner-pagination {
  position: absolute;
  bottom: 30px;
  left: 30px;
  display: flex;
  margin-right: 18px;
  z-index: 1;
}

.banner-pagination i {
  position: relative;
  border-radius: 50%;
  border: 4px solid transparent;
  cursor: pointer;
}

.banner-pagination i:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
}

.banner-pagination i.active {
  border-color: rgba(255, 255, 255, 0.3);
}

.banner-pagination i:not(:last-child) {
  margin-right: 12px;
}

.search {
  position: relative;
  z-index: 2;
  margin-bottom: 65px;
  width: 100%;
}

@media (max-width: 1679px) {
  .search {
    margin-bottom: 61.66667px;
  }
}

@media (max-width: 1439px) {
  .search {
    margin-bottom: 58.33333px;
  }
}

@media (max-width: 1365px) {
  .search {
    margin-bottom: 55px;
  }
}

.search-wrapper {
  display: flex;
  justify-content: flex-end;
}

.search-body {
  background-color: #F2F6F7;
  padding-top: 45px;
  padding-left: 100px;
  padding-bottom: 45px;
  padding-right: 100px;
  width: 76%;
}

@media (max-width: 1679px) {
  .search-body {
    padding-top: 40px;
  }
}

@media (max-width: 1439px) {
  .search-body {
    padding-top: 35px;
  }
}

@media (max-width: 1365px) {
  .search-body {
    padding-top: 30px;
  }
}

@media (max-width: 1679px) {
  .search-body {
    padding-left: 90px;
  }
}

@media (max-width: 1439px) {
  .search-body {
    padding-left: 80px;
  }
}

@media (max-width: 1365px) {
  .search-body {
    padding-left: 70px;
  }
}

@media (max-width: 1679px) {
  .search-body {
    padding-bottom: 40px;
  }
}

@media (max-width: 1439px) {
  .search-body {
    padding-bottom: 35px;
  }
}

@media (max-width: 1365px) {
  .search-body {
    padding-bottom: 30px;
  }
}

@media (max-width: 1679px) {
  .search-body {
    padding-right: 90px;
  }
}

@media (max-width: 1439px) {
  .search-body {
    padding-right: 80px;
  }
}

@media (max-width: 1365px) {
  .search-body {
    padding-right: 70px;
  }
}

.search-more {
  margin-left: 28px;
  color: #009DA0;
}

.search-map {
  width: 24%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-image: url(./images/index-search-map.jpg);
  background-size: cover;
  background-position: center center;
  padding-left: 110px;
  padding-right: 110px;
  font-size: 22px;
}

@media (max-width: 1679px) {
  .search-map {
    padding-left: 96.66667px;
  }
}

@media (max-width: 1439px) {
  .search-map {
    padding-left: 83.33333px;
  }
}

@media (max-width: 1365px) {
  .search-map {
    padding-left: 70px;
  }
}

@media (max-width: 1679px) {
  .search-map {
    padding-right: 96.66667px;
  }
}

@media (max-width: 1439px) {
  .search-map {
    padding-right: 83.33333px;
  }
}

@media (max-width: 1365px) {
  .search-map {
    padding-right: 70px;
  }
}

@media (max-width: 1679px) {
  .search-map {
    font-size: 20.66667px;
  }
}

@media (max-width: 1439px) {
  .search-map {
    font-size: 19.33333px;
  }
}

@media (max-width: 1365px) {
  .search-map {
    font-size: 18px;
  }
}

.search-map i {
  font-size: 40px;
  margin-right: 8px;
}

.search-map:before {
  content: "";
  transition: all .15s;
  background-color: rgba(0, 0, 0, 0.14);
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.search-map:hover:before {
  opacity: 1;
}

.project {
  margin-bottom: 80px;
}

@media (max-width: 1679px) {
  .project {
    margin-bottom: 73.33333px;
  }
}

@media (max-width: 1439px) {
  .project {
    margin-bottom: 66.66667px;
  }
}

@media (max-width: 1365px) {
  .project {
    margin-bottom: 60px;
  }
}

.project-title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 40px;
}

@media (max-width: 1679px) {
  .project-title {
    font-size: 36.66667px;
  }
}

@media (max-width: 1439px) {
  .project-title {
    font-size: 33.33333px;
  }
}

@media (max-width: 1365px) {
  .project-title {
    font-size: 30px;
  }
}

@media (max-width: 1679px) {
  .project-title {
    margin-bottom: 36.66667px;
  }
}

@media (max-width: 1439px) {
  .project-title {
    margin-bottom: 33.33333px;
  }
}

@media (max-width: 1365px) {
  .project-title {
    margin-bottom: 30px;
  }
}

.project-tab {
  color: #868686;
  margin-bottom: 127px;
}

@media (max-width: 1679px) {
  .project-tab {
    margin-bottom: 111.33333px;
  }
}

@media (max-width: 1439px) {
  .project-tab {
    margin-bottom: 95.66667px;
  }
}

@media (max-width: 1365px) {
  .project-tab {
    margin-bottom: 80px;
  }
}

.project-tab, .project-tab-item, .project-tab-item i {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-tab-item p {
  font-size: 22px;
}

@media (max-width: 1679px) {
  .project-tab-item p {
    font-size: 20.66667px;
  }
}

@media (max-width: 1439px) {
  .project-tab-item p {
    font-size: 19.33333px;
  }
}

@media (max-width: 1365px) {
  .project-tab-item p {
    font-size: 18px;
  }
}

.project-tab-item i {
  width: 16px;
  height: 16px;
  font-style: normal;
  margin: 0 12px;
}

.project-tab-item i:nth-child(1) {
  display: none;
}

.project-tab-item i:nth-child(2) {
  font-weight: bold;
}

.project-tab-item:hover {
  color: #009DA0;
}

.project-tab-item.active {
  color: #009DA0;
}

.project-tab-item.active i:nth-child(1) {
  display: flex;
}

.project-tab-item.active i:nth-child(2) {
  display: none;
}

.project-area {
  position: relative;
  margin-left: 50px;
  padding-top: 38px;
  padding-left: 80px;
  padding-bottom: 50px;
}

@media (max-width: 1679px) {
  .project-area {
    padding-top: 35.33333px;
  }
}

@media (max-width: 1439px) {
  .project-area {
    padding-top: 32.66667px;
  }
}

@media (max-width: 1365px) {
  .project-area {
    padding-top: 30px;
  }
}

@media (max-width: 1679px) {
  .project-area {
    padding-left: 70px;
  }
}

@media (max-width: 1439px) {
  .project-area {
    padding-left: 60px;
  }
}

@media (max-width: 1365px) {
  .project-area {
    padding-left: 50px;
  }
}

@media (max-width: 1679px) {
  .project-area {
    padding-bottom: 46.66667px;
  }
}

@media (max-width: 1439px) {
  .project-area {
    padding-bottom: 43.33333px;
  }
}

@media (max-width: 1365px) {
  .project-area {
    padding-bottom: 40px;
  }
}

.project-area:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 140%;
  height: 110%;
  background-color: #F2F6F7;
}

.project-area > * {
  position: relative;
}

.project-area-wrapper {
  position: relative;
  height: 420px;
}

.project-area-title {
  color: #009DA0;
  position: relative;
  font-size: 32px;
  padding-bottom: 20px;
  margin-bottom: 50px;
}

@media (max-width: 1679px) {
  .project-area-title {
    font-size: 30px;
  }
}

@media (max-width: 1439px) {
  .project-area-title {
    font-size: 28px;
  }
}

@media (max-width: 1365px) {
  .project-area-title {
    font-size: 26px;
  }
}

@media (max-width: 1679px) {
  .project-area-title {
    padding-bottom: 18.66667px;
  }
}

@media (max-width: 1439px) {
  .project-area-title {
    padding-bottom: 17.33333px;
  }
}

@media (max-width: 1365px) {
  .project-area-title {
    padding-bottom: 16px;
  }
}

@media (max-width: 1679px) {
  .project-area-title {
    margin-bottom: 41.66667px;
  }
}

@media (max-width: 1439px) {
  .project-area-title {
    margin-bottom: 33.33333px;
  }
}

@media (max-width: 1365px) {
  .project-area-title {
    margin-bottom: 25px;
  }
}

.project-area-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #009DA0;
}

.project-area-link {
  color: #868686;
  font-size: 18px;
  margin-bottom: 40px;
}

@media (max-width: 1679px) {
  .project-area-link {
    font-size: 17.33333px;
  }
}

@media (max-width: 1439px) {
  .project-area-link {
    font-size: 16.66667px;
  }
}

@media (max-width: 1365px) {
  .project-area-link {
    font-size: 16px;
  }
}

@media (max-width: 1679px) {
  .project-area-link {
    margin-bottom: 36.66667px;
  }
}

@media (max-width: 1439px) {
  .project-area-link {
    margin-bottom: 33.33333px;
  }
}

@media (max-width: 1365px) {
  .project-area-link {
    margin-bottom: 30px;
  }
}

.project-area-link a {
  display: block;
  padding: 13px 0;
}

.project-area-link a:hover {
  color: #009DA0;
}

.project-area-desc {
  color: #868686;
  line-height: 2;
}

.project-area-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}

.project-area-control {
  position: relative;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  color: #009DA0;
  margin-right: 15px;
}

.project-area-control:after {
  content: "";
  border: 2px solid #009DA0;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.3);
  filter: blur(3px);
  transition: all .4s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.project-area-control:hover:after {
  opacity: 1;
  transform: scale(1);
  filter: blur(0);
}

.project-item {
  position: relative;
  width: 400px;
  height: 470px;
  box-sizing: border-box;
}

.project-item .comp-project {
  height: 100%;
}

.project-body {
  cursor: move;
}

.project-body-item {
  display: none;
}

.project-body-item.active {
  display: block;
}

.project-swiper-s {
  padding-top: 10px;
}

.project-more {
  text-align: center;
  height: 100%;
  background-image: url(./images/index-project-more.jpg);
  color: #009DA0;
  background-size: cover;
  background-position: center center;
  font-size: 22px;
  padding-top: 135px;
}

@media (max-width: 1679px) {
  .project-more {
    font-size: 20.66667px;
  }
}

@media (max-width: 1439px) {
  .project-more {
    font-size: 19.33333px;
  }
}

@media (max-width: 1365px) {
  .project-more {
    font-size: 18px;
  }
}

@media (max-width: 1679px) {
  .project-more {
    padding-top: 123.33333px;
  }
}

@media (max-width: 1439px) {
  .project-more {
    padding-top: 111.66667px;
  }
}

@media (max-width: 1365px) {
  .project-more {
    padding-top: 100px;
  }
}

.link-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  overflow: hidden;
  font-size: 22px;
  height: 172px;
}

@media (max-width: 1679px) {
  .link-item {
    font-size: 20.66667px;
  }
}

@media (max-width: 1439px) {
  .link-item {
    font-size: 19.33333px;
  }
}

@media (max-width: 1365px) {
  .link-item {
    font-size: 18px;
  }
}

@media (max-width: 1679px) {
  .link-item {
    height: 164.66667px;
  }
}

@media (max-width: 1439px) {
  .link-item {
    height: 157.33333px;
  }
}

@media (max-width: 1365px) {
  .link-item {
    height: 150px;
  }
}

.link-item-pic {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
}

.link-item p {
  position: relative;
}

.link-item-pic {
  transition: all .3s;
}

.link-item:hover .link-item-pic {
  transform: scale(1.1);
}

.activity {
  margin-bottom: 75px;
}

@media (max-width: 1679px) {
  .activity {
    margin-bottom: 70px;
  }
}

@media (max-width: 1439px) {
  .activity {
    margin-bottom: 65px;
  }
}

@media (max-width: 1365px) {
  .activity {
    margin-bottom: 60px;
  }
}

.activity-pic, .activity-body {
  height: 580px;
}

@media (max-width: 1679px) {
  .activity-pic, .activity-body {
    height: 553.33333px;
  }
}

@media (max-width: 1439px) {
  .activity-pic, .activity-body {
    height: 526.66667px;
  }
}

@media (max-width: 1365px) {
  .activity-pic, .activity-body {
    height: 500px;
  }
}

.activity-pic-item {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
}

.activity-control {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 5;
  height: 70px;
  padding-left: 34px;
  padding-right: 34px;
  color: #fff;
  background-color: #009DA0;
}

.activity-control-pagination {
  display: flex;
  margin-right: 18px;
}

.activity-control-pagination i {
  position: relative;
  border-radius: 50%;
  border: 4px solid transparent;
}

.activity-control-pagination i:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
}

.activity-control-pagination i.active {
  border-color: rgba(255, 255, 255, 0.3);
}

.activity-control-pagination i:not(:last-child) {
  margin-right: 12px;
}

.activity-control-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  outline: none;
}

.activity-control-btn:after {
  content: "";
  border: 2px solid #fff;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.3);
  filter: blur(3px);
  transition: all .4s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.activity-control-btn:hover:after {
  opacity: 1;
  transform: scale(1);
  filter: blur(0);
}

.activity-control-index {
  margin: 0 15px;
}

.activity-body {
  background-color: #F2F6F7;
  padding-top: 110px;
}

@media (max-width: 1679px) {
  .activity-body {
    padding-top: 103.33333px;
  }
}

@media (max-width: 1439px) {
  .activity-body {
    padding-top: 96.66667px;
  }
}

@media (max-width: 1365px) {
  .activity-body {
    padding-top: 90px;
  }
}

.activity-title {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 40px;
  margin-bottom: 80px;
}

@media (max-width: 1679px) {
  .activity-title {
    font-size: 37.33333px;
  }
}

@media (max-width: 1439px) {
  .activity-title {
    font-size: 34.66667px;
  }
}

@media (max-width: 1365px) {
  .activity-title {
    font-size: 32px;
  }
}

@media (max-width: 1679px) {
  .activity-title {
    margin-bottom: 75px;
  }
}

@media (max-width: 1439px) {
  .activity-title {
    margin-bottom: 70px;
  }
}

@media (max-width: 1365px) {
  .activity-title {
    margin-bottom: 65px;
  }
}

.activity-title:before {
  content: "";
  height: 1px;
  width: 60%;
  margin-right: 16px;
  background-color: #009DA0;
}

.activity-item {
  padding-left: 70px;
  padding-right: 70px;
}

@media (max-width: 1679px) {
  .activity-item {
    padding-left: 63.33333px;
  }
}

@media (max-width: 1439px) {
  .activity-item {
    padding-left: 56.66667px;
  }
}

@media (max-width: 1365px) {
  .activity-item {
    padding-left: 50px;
  }
}

@media (max-width: 1679px) {
  .activity-item {
    padding-right: 63.33333px;
  }
}

@media (max-width: 1439px) {
  .activity-item {
    padding-right: 56.66667px;
  }
}

@media (max-width: 1365px) {
  .activity-item {
    padding-right: 50px;
  }
}

.activity-item-title {
  color: #595a5b;
  font-size: 28px;
  margin-bottom: 36px;
}

@media (max-width: 1679px) {
  .activity-item-title {
    font-size: 26.66667px;
  }
}

@media (max-width: 1439px) {
  .activity-item-title {
    font-size: 25.33333px;
  }
}

@media (max-width: 1365px) {
  .activity-item-title {
    font-size: 24px;
  }
}

@media (max-width: 1679px) {
  .activity-item-title {
    margin-bottom: 32px;
  }
}

@media (max-width: 1439px) {
  .activity-item-title {
    margin-bottom: 28px;
  }
}

@media (max-width: 1365px) {
  .activity-item-title {
    margin-bottom: 24px;
  }
}

.activity-item-desc {
  line-height: 1.8;
  color: #868686;
  margin-bottom: 36px;
}

@media (max-width: 1679px) {
  .activity-item-desc {
    margin-bottom: 32px;
  }
}

@media (max-width: 1439px) {
  .activity-item-desc {
    margin-bottom: 28px;
  }
}

@media (max-width: 1365px) {
  .activity-item-desc {
    margin-bottom: 24px;
  }
}

.activity-item-more {
  color: #009DA0;
}

.news1 {
  margin-bottom: 75px;
}

@media (max-width: 1679px) {
  .news1 {
    margin-bottom: 70px;
  }
}

@media (max-width: 1439px) {
  .news1 {
    margin-bottom: 65px;
  }
}

@media (max-width: 1365px) {
  .news1 {
    margin-bottom: 60px;
  }
}

.news1-title, .news1-item {
  height: 290px;
}

@media (max-width: 1679px) {
  .news1-title, .news1-item {
    height: 283.33333px;
  }
}

@media (max-width: 1439px) {
  .news1-title, .news1-item {
    height: 276.66667px;
  }
}

@media (max-width: 1365px) {
  .news1-title, .news1-item {
    height: 270px;
  }
}

.news1-title {
  display: flex;
  align-items: center;
  background-color: #F2F6F7;
  font-size: 40px;
  padding-left: 100px;
}

@media (max-width: 1679px) {
  .news1-title {
    font-size: 38.66667px;
  }
}

@media (max-width: 1439px) {
  .news1-title {
    font-size: 37.33333px;
  }
}

@media (max-width: 1365px) {
  .news1-title {
    font-size: 36px;
  }
}

@media (max-width: 1679px) {
  .news1-title {
    padding-left: 93.33333px;
  }
}

@media (max-width: 1439px) {
  .news1-title {
    padding-left: 86.66667px;
  }
}

@media (max-width: 1365px) {
  .news1-title {
    padding-left: 80px;
  }
}

.news1-title:after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background-color: #009DA0;
  margin-left: 16px;
}

.news1-item {
  display: flex;
  overflow: hidden;
  font-size: 14px;
}

.news1-item {
  background-color: #fff;
}

.news1-item.reverse {
  background-color: #F2F6F7;
}

.news1-item-pic {
  position: relative;
  height: 100%;
  width: calc(50% + 20px);
  flex-shrink: 0;
  overflow: hidden;
}

.news1-item-pic b {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
}

.reverse .news1-item-pic b {
  left: auto;
}

.news1-item-pic b {
  transition: all .15s;
}

a:hover .news1-item-pic b {
  transform: scale(1.1);
}

.news1-item-pic:before, .news1-item-pic:after {
  content: "";
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 20px;
}

.news1-item-pic:before, .news1-item-pic:after {
  background-color: #fff;
}

.reverse .news1-item-pic:before, .reverse .news1-item-pic:after {
  background-color: #F2F6F7;
}

.news1-item-pic:before, .news1-item-pic:after {
  right: 0;
}

.reverse .news1-item-pic:before, .reverse .news1-item-pic:after {
  left: 0;
}

.news1-item-pic:before {
  bottom: 50%;
}

.news1-item-pic:before {
  transform: skewY(45deg);
}

.reverse .news1-item-pic:before {
  transform: skewY(-45deg);
}

.news1-item-pic:after {
  top: 50%;
}

.news1-item-pic:after {
  transform: skewY(-45deg);
}

.reverse .news1-item-pic:after {
  transform: skewY(45deg);
}

.news1-item-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
  padding-top: 36px;
  padding-bottom: 36px;
  padding-left: 58px;
  padding-right: 58px;
}

@media (max-width: 1679px) {
  .news1-item-text {
    padding-top: 34px;
  }
}

@media (max-width: 1439px) {
  .news1-item-text {
    padding-top: 32px;
  }
}

@media (max-width: 1365px) {
  .news1-item-text {
    padding-top: 30px;
  }
}

@media (max-width: 1679px) {
  .news1-item-text {
    padding-bottom: 34px;
  }
}

@media (max-width: 1439px) {
  .news1-item-text {
    padding-bottom: 32px;
  }
}

@media (max-width: 1365px) {
  .news1-item-text {
    padding-bottom: 30px;
  }
}

@media (max-width: 1679px) {
  .news1-item-text {
    padding-left: 55.33333px;
  }
}

@media (max-width: 1439px) {
  .news1-item-text {
    padding-left: 52.66667px;
  }
}

@media (max-width: 1365px) {
  .news1-item-text {
    padding-left: 50px;
  }
}

@media (max-width: 1679px) {
  .news1-item-text {
    padding-right: 55.33333px;
  }
}

@media (max-width: 1439px) {
  .news1-item-text {
    padding-right: 52.66667px;
  }
}

@media (max-width: 1365px) {
  .news1-item-text {
    padding-right: 50px;
  }
}

.news1-item-time {
  color: #868686;
  margin-bottom: 20px;
}

@media (max-width: 1679px) {
  .news1-item-time {
    margin-bottom: 19.33333px;
  }
}

@media (max-width: 1439px) {
  .news1-item-time {
    margin-bottom: 18.66667px;
  }
}

@media (max-width: 1365px) {
  .news1-item-time {
    margin-bottom: 18px;
  }
}

.news1-item-title {
  line-height: 1.3;
  font-size: 24px;
}

@media (max-width: 1679px) {
  .news1-item-title {
    font-size: 22.66667px;
  }
}

@media (max-width: 1439px) {
  .news1-item-title {
    font-size: 21.33333px;
  }
}

@media (max-width: 1365px) {
  .news1-item-title {
    font-size: 20px;
  }
}

.news1-item-category {
  color: #868686;
}

.news2 {
  margin-bottom: 75px;
}

@media (max-width: 1679px) {
  .news2 {
    margin-bottom: 70px;
  }
}

@media (max-width: 1439px) {
  .news2 {
    margin-bottom: 65px;
  }
}

@media (max-width: 1365px) {
  .news2 {
    margin-bottom: 60px;
  }
}

.news2-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}

@media (max-width: 1679px) {
  .news2-header {
    margin-bottom: 43.5px;
  }
}

@media (max-width: 1439px) {
  .news2-header {
    margin-bottom: 42px;
  }
}

@media (max-width: 1365px) {
  .news2-header {
    margin-bottom: 40.5px;
  }
}

.news2-title {
  font-size: 40px;
}

@media (max-width: 1679px) {
  .news2-title {
    font-size: 38.66667px;
  }
}

@media (max-width: 1439px) {
  .news2-title {
    font-size: 37.33333px;
  }
}

@media (max-width: 1365px) {
  .news2-title {
    font-size: 36px;
  }
}

.news2-tab {
  display: flex;
  font-size: 22px;
}

@media (max-width: 1679px) {
  .news2-tab {
    font-size: 20.66667px;
  }
}

@media (max-width: 1439px) {
  .news2-tab {
    font-size: 19.33333px;
  }
}

@media (max-width: 1365px) {
  .news2-tab {
    font-size: 18px;
  }
}

.news2-tab-item {
  display: flex;
  align-items: center;
}

.news2-tab-item:not(:last-child):after {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #C2C2C2;
  margin-left: 26px;
  margin-right: 26px;
}

@media (max-width: 1679px) {
  .news2-tab-item:not(:last-child):after {
    margin-left: 24px;
  }
}

@media (max-width: 1439px) {
  .news2-tab-item:not(:last-child):after {
    margin-left: 22px;
  }
}

@media (max-width: 1365px) {
  .news2-tab-item:not(:last-child):after {
    margin-left: 20px;
  }
}

@media (max-width: 1679px) {
  .news2-tab-item:not(:last-child):after {
    margin-right: 24px;
  }
}

@media (max-width: 1439px) {
  .news2-tab-item:not(:last-child):after {
    margin-right: 22px;
  }
}

@media (max-width: 1365px) {
  .news2-tab-item:not(:last-child):after {
    margin-right: 20px;
  }
}

.news2-tab-item p {
  padding: 12px 0;
}

.news2-tab-item p {
  transition: all .15s;
  border-bottom: 1px solid transparent;
}

.news2-tab-item.active p, .news2-tab-item:hover p {
  color: #009DA0;
  border-bottom: 1px solid #009DA0;
}

.news2-body-item {
  box-sizing: border-box;
}

.news2-list {
  background-color: #F2F6F7;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 90px;
  padding-right: 90px;
}

@media (max-width: 1679px) {
  .news2-list {
    padding-top: 31.66667px;
  }
}

@media (max-width: 1439px) {
  .news2-list {
    padding-top: 28.33333px;
  }
}

@media (max-width: 1365px) {
  .news2-list {
    padding-top: 25px;
  }
}

@media (max-width: 1679px) {
  .news2-list {
    padding-bottom: 31.66667px;
  }
}

@media (max-width: 1439px) {
  .news2-list {
    padding-bottom: 28.33333px;
  }
}

@media (max-width: 1365px) {
  .news2-list {
    padding-bottom: 25px;
  }
}

@media (max-width: 1679px) {
  .news2-list {
    padding-left: 86.66667px;
  }
}

@media (max-width: 1439px) {
  .news2-list {
    padding-left: 83.33333px;
  }
}

@media (max-width: 1365px) {
  .news2-list {
    padding-left: 80px;
  }
}

@media (max-width: 1679px) {
  .news2-list {
    padding-right: 86.66667px;
  }
}

@media (max-width: 1439px) {
  .news2-list {
    padding-right: 83.33333px;
  }
}

@media (max-width: 1365px) {
  .news2-list {
    padding-right: 80px;
  }
}

.news2-item {
  display: block;
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (max-width: 1679px) {
  .news2-item {
    padding-top: 23.33333px;
  }
}

@media (max-width: 1439px) {
  .news2-item {
    padding-top: 21.66667px;
  }
}

@media (max-width: 1365px) {
  .news2-item {
    padding-top: 20px;
  }
}

@media (max-width: 1679px) {
  .news2-item {
    padding-bottom: 23.33333px;
  }
}

@media (max-width: 1439px) {
  .news2-item {
    padding-bottom: 21.66667px;
  }
}

@media (max-width: 1365px) {
  .news2-item {
    padding-bottom: 20px;
  }
}

.news2-item:not(:last-child) {
  border-bottom: 1px solid #C4D3D7;
}

.news2-item-time {
  color: #868686;
  font-size: 14px;
  padding-left: 12px;
}

.news2-item-title {
  margin-bottom: 10px;
  line-height: 1.2;
  font-size: 18px;
}

.news2-item-category {
  color: #868686;
  font-size: 14px;
}

.news2-item-title {
  transition: all .15s;
}

.news2-item:hover .news2-item-title {
  color: #009DA0;
}

.news2-more {
  color: #009DA0;
  text-align: right;
  margin-top: 25px;
}

@media (max-width: 1679px) {
  .news2-more {
    margin-top: 23.33333px;
  }
}

@media (max-width: 1439px) {
  .news2-more {
    margin-top: 21.66667px;
  }
}

@media (max-width: 1365px) {
  .news2-more {
    margin-top: 20px;
  }
}

.news2-pic-swiper {
  height: 100%;
}

.news2-pic-item {
  background-color: #000;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.news2-pic-item b {
  opacity: 0.8;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
}

.news2-pic-control .comp-control-btn-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.news2-pic-control .comp-control-btn-item.prev {
  left: 15px;
}

.news2-pic-control .comp-control-btn-item.next {
  right: 15px;
}

.news2-pic-control .comp-control-btn-item:after {
  transform: scale(1);
  filter: blur(0);
  opacity: 1;
}

.news2-pic-footer {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  font-size: 16px;
  padding: 20px;
  background-color: rgba(0, 157, 160, 0.7);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.news2-pic-footer p {
  width: 70%;
}

.news2-pic-control, .news2-pic-footer {
  opacity: 0;
  transition: all .2s;
}

.news2-pic:hover .news2-pic-control,
.news2-pic:hover .news2-pic-footer {
  opacity: 1;
}
